import Dragger from 'antd/lib/upload/Dragger';
import L from 'leaflet';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { Footer } from '../FieldListContainer';
import { ReactComponent as Conflict } from '../../../assets/images/Conflict.svg';
import { ReactComponent as CircledCheckGreen } from '../../../assets/images/CircledCheckGreen.svg';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { connect, ConnectedProps } from 'react-redux';
import { makeDispatch, pathJoin } from '../../../logic/Utility/Utils';
import { getBulkImportGeometry, getBulkImportHeader, getBulkImportProfile, saveBulkImportSession } from '../../../logic/store/BulkImport/BulkImportThunks';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { sortBy, uniq } from 'lodash';
import { BulkImportMap } from '../../../logic/Map/BulkImportMap/BulkImportMap';
import styled, { useTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { BulkImportColumnSelect } from './BulkImportColumnSelect';
import { BulkImportFarmFieldTree } from './BulkImportFarmFieldTree';
import { clearState, setFarmKey, setFieldKey } from '../../../logic/store/BulkImport/BulkImportSlice';
import { Alert, Tooltip } from 'antd';
import { getCurrentActingUser } from '../../../logic/store/User/AuthSlice';
import { FeatureCollection } from '@turf/turf';
import { ScaleLoader } from 'react-spinners';
import { CoveringLoader } from '../../../components/LoadingSpinner/CoveringLoader';
import { ConnectedBackToFieldList } from '../BackToFieldList';

const BulkImportContainer = styled.div`
	height: 100%;
	width: calc(100% - 70px);
	margin-left: 70px;
	display: flex;
	flex-direction: row;
	.section {
		padding: 16px 0;
		border-top: none;
		font-weight: ${themeGet('fontWeights.bold')};
		border-bottom: thin solid ${themeGet('colors.lightestGrey')};
		&.fieldSection {
			display: flex;
			flex-direction: column;
			border-bottom: none;
			flex-grow: 1;
		}
		&.groupSection {
			min-height: 100px;
			display: flex;
			flex-direction: column;
		}
	}
	.header {
		padding: 32px 0;
	}
`;

const BulkImportComponent = (props: PropsFromRedux) =>
{
	const {
		AuthToken,
		FarmKey,
		FieldKey,
		Geometry,
		Header,
		getGeometry,
		getHeader,
		getProfile,
		LoadingGeometry,
		LoadingProfile,
		clearBulkImport,
		SelectedGrowerId,
		SelectedUserId,
		setFarmKey,
		setFieldKey,
		farms,
		saveBulkImport
	} = props;
	const history = useHistory();
	const theme = useTheme();
	
	const [uploading, setUploading] = useState(false);
	const [columnNames, setColumnNames] = useState<string[]>([]);
	const [fileUploaded, setFileUploaded] = useState(false);
	const [fileParseError, setFileParseError] = useState<string>();

	const [hoverFarmFieldId, setHoverFarmFieldId] = useState<string>();
	const [jumpTo, setJumpTo] = useState<{ zoom?: string; scroll?: string }>({
		zoom: undefined,
		scroll: undefined,
	});
	const scrollTo = useCallback((scrollTo: string) => setJumpTo({ scroll: scrollTo }), [setJumpTo]);
	const zoomTo = useCallback((scrollTo: string) => setJumpTo({ zoom: scrollTo }), [setJumpTo]);

	const hasConflicts = useMemo(() => Object.values(farms ?? {}).some(farm => Object.values(farm.Fields).some(field => field.currentConflicts.length > 0)), [farms]);

	const mapRef = useRef<L.DrawMap | undefined>(undefined);
	const setMapRef = useCallback((map: L.DrawMap) => { mapRef.current = map; }, []);
	const cancelBulkImportSession = useCallback(() =>
	{
		history.push('/fieldactivities');
	}, [history]);

	const saveBulkImportSession = useCallback(async () =>
	{
		const result = await saveBulkImport(
			{
				userId: SelectedUserId, 
				farmKey: FarmKey, 
				fieldKey: FieldKey
			});
			
		if(result.payload?.Status === 'Completed')
		{
			cancelBulkImportSession();
		}
	}, [saveBulkImport, SelectedUserId, FarmKey, FieldKey]);

	const fetchParsedTree = useCallback(async () =>
	{
		await getHeader({ UserId: SelectedUserId });
	}, [getHeader, SelectedUserId ]);

	useEffect(() =>
	{
		// any time this component is unmounted, the bulk import state should be reset
		return () =>
		{
			clearBulkImport();
		};
	}, [clearBulkImport]);

	const onUploadChange = useCallback((info: UploadChangeParam<UploadFile<any>>) =>
	{
		setFileUploaded(false);
		setUploading(info.file.status === 'uploading');
		if (info.file.status === 'done' || info.file.status === 'success')
		{
			fetchParsedTree();
		}
	}, [fetchParsedTree]);

	useEffect(() =>
	{
		if (Header)
		{
			setFileParseError(undefined);
			if (Header.Progress !== 100)
			{
				setTimeout(fetchParsedTree, 500);
			}
			else
			{
				// Rather than _all possible_ columns, we can only show the columns that exist across _all_ files provided.
				const optionsForColumns = uniq(Header.Files.flatMap(f => Object.keys(f.Columns))).sort()
					.filter(columnName => Header.Files.every(f => Object.keys(f.Columns).includes(columnName)));

				// If there are no columns (either because there were no string columns, or none in common across all shapefiles)
				// show an error.
				if(optionsForColumns.length === 0)
				{
					setFileParseError(' Files with different import types must be uploaded separately. Files with no columns cannot be imported. Please check your upload to follow these restrictions.');
				}

				setColumnNames(optionsForColumns);
				const farm = optionsForColumns.find(option => option.toLowerCase().includes('farm')) ?? optionsForColumns[0];
				setFarmKey(farm);
				const field = optionsForColumns.find(option => option.toLowerCase().includes('field')) ?? optionsForColumns[0];
				setFieldKey(field);
				
				setFileUploaded(true);
			}
		}
	}, [getGeometry, fetchParsedTree, SelectedUserId, Header, SelectedGrowerId]);

	useEffect(() =>
	{
		if (FarmKey && FieldKey)
		{
			getGeometry({ FarmKey: FarmKey, FieldKey: FieldKey, GrowerId: SelectedGrowerId, UserId: SelectedUserId });
			getProfile({ FarmKey: FarmKey, FieldKey: FieldKey, GrowerId: SelectedGrowerId, UserId: SelectedUserId });
		}
	}, [getGeometry, getProfile, FarmKey, FieldKey, SelectedGrowerId, SelectedUserId]);

	const zoomToField = useCallback((geometry: FeatureCollection) =>
	{
		mapRef.current?.fitBounds(L.geoJSON(geometry).getBounds());
	}, []);

	const selectNextConflict = useCallback(() => 
	{
		if(!farms)
		{
			return;
		}
		const allConflicts = Object.values(farms).flatMap(farm => sortBy(Object.values(farm.Fields), field => -field.Overlaps.length, field => field.Name).map(field => ({farm, field}))).filter(entry => entry.field.currentConflicts.length);
		if(allConflicts.length < 1)
		{
			return;
		}
		const nextConflictId = `${allConflicts[0].farm.Id}:${allConflicts[0].field.Id}`;
		setJumpTo({ scroll: nextConflictId, zoom: nextConflictId});
		setHoverFarmFieldId(nextConflictId);
	}, 
	[farms, setJumpTo, setHoverFarmFieldId]);

	return <BulkImportContainer>
		{/* setting width here is weird for some reason, set min and max width to the same value to force it to behave */}
		<div style={{ display: 'flex', flexDirection: 'column', maxWidth: 382, minWidth: 382, position: 'relative' }}>
			<ConnectedBackToFieldList />
			<div className='sectionContainer'
				style={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					overflow: 'hidden',
				}}
			>
				<div className='sectionInner' style={{ padding: '0 16px', height: '94%', display: 'flex', flexDirection: 'column', overflow: 'hidden', alignItems: 'stretch', justifyContent: 'start' }}>
					{(!fileUploaded || (fileUploaded && fileParseError)) // Either if we haven't uploaded or the upload had an error.
						? ( <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems:'stretch', justifyContent: 'center'}}>
							{(fileUploaded && fileParseError) && <Alert type='error' showIcon={true} description={fileParseError} style={{marginTop: 32, marginBottom: 32}} />}
							<Dragger
								accept='application/x-zip-compressed,application/zip,zip,application/x-zip'
								action={pathJoin(process.env.REACT_APP_API_HOST as string, '/api/6', `users/${SelectedUserId}/bulkimport/growers/${SelectedGrowerId}`)}
								// prevent another file being added while one is still uploading
								disabled={uploading}
								headers={{ Authorization: `Bearer ${AuthToken}` }}
								// replaces file if a new one is uploaded
								maxCount={1}
								method='PUT'
								// only disallows uploading multiple files at once
								multiple={false}
								onChange={onUploadChange}
								progress={{ format: percent => percent && `${percent.toFixed(0)}%`, strokeWidth: 3 }}
								// required for the progress bar
								showUploadList={true}
								style={{
									backgroundColor: 'white',
									padding: '16px 22px'
								}}
							>
								<div style={{ 
									textAlign: 'center',
									fontSize: 14, 
									fontWeight: 400,
									marginBottom: 16,
									color: theme.colors.mediumGrey
								}}>
									Drag and drop files here to<br/>
									import fields
								</div>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: theme.colors.mediumGrey }}>
									<div style={{width:'100%', borderTop: 'solid 1px', borderColor: theme.colors.mediumGrey}}/>
									<div style={{ marginLeft: 16, marginRight: 16}}>or</div>
									<div style={{width:'100%', borderTop: 'solid 1px', borderColor: theme.colors.mediumGrey}}/>
								</div>
								<div style={{
									marginTop: 16
								}}>
									<Button
										style={{ width:100, height:32, padding: 0, fontSize: 14, fontWeight: 700 }}
										variant='outlined'
										className='bulkFieldBrowseButton'
										disabled={uploading}
									>
										Browse
									</Button>
								</div>
							</Dragger>
						</div>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
								<BulkImportColumnSelect columnNames={columnNames} selectNextConflict={selectNextConflict} />
								<div style={{ overflowY: 'auto' }}>
									<BulkImportFarmFieldTree 
										zoomToField={zoomToField} 
										setHoverFarmFieldId={setHoverFarmFieldId} 
										hoverFarmFieldId={hoverFarmFieldId}
										scrollTo={jumpTo.scroll} />
								</div>
							</div>
							
						)
					}
				</div>
				<Footer style={{display: 'flex', zIndex: 1, flexDirection: 'column', padding: 10, paddingRight: 20, marginTop: 6 }}>
					<div style={{marginLeft: 'auto', marginBottom: 5}}>
						<Tooltip title={hasConflicts ? 'Click this to select the next conflict' : undefined}>
							<div style={{
								textDecoration: hasConflicts ? 'underline' : undefined, 
								cursor: hasConflicts ? 'pointer' : undefined,
								display: 'flex', 
								justifyContent: 'center', 
							}} 
							onClick={selectNextConflict}>
								{
									farms ? hasConflicts ?	<Conflict width={20} height={20} style={{top: 1, position: 'relative', marginRight: 5}}/> 
										: <CircledCheckGreen width={16} height={16} style={{top: 3, position: 'relative', marginRight: 5}}/> : ''
								}
								{
									farms ? hasConflicts ?	'All conflicts must first be resolved' : 'All conflicts are resolved' : ''
								}
							</div>
						</Tooltip>
					</div>
					<div style={{marginLeft: 'auto', flexDirection: 'row'}}>
						<Button
							style={{ width:100, height:32, padding: 0, fontSize: 14, fontWeight: 700 }}
							variant='outlined'
							className='bulkFieldEditButton'
							onClick={cancelBulkImportSession}
						>
							Cancel
						</Button>
						<Button
							style={{ width:100, height:32, padding: 0, fontSize: 14, fontWeight: 700, marginLeft: 10 }}
							variant='dark'
							className='bulkEditNamesButton'
							disabled= {!farms || hasConflicts}
							onClick={saveBulkImportSession}
						>
							Save
						</Button>
					</div>
				</Footer>
			</div>
			<CoveringLoader className={(LoadingGeometry || LoadingProfile) ? 'loading' : ''}  style={{backgroundColor: '#ddda'}}>
				<ScaleLoader color={theme.colors.primary} loading={LoadingGeometry || LoadingProfile} /> 
			</CoveringLoader>
		</div>
		<BulkImportMap setMapRef={setMapRef} setHover={setHoverFarmFieldId} hoverFarmFieldId={hoverFarmFieldId} onSelect={scrollTo} focusField={jumpTo.zoom} />
	</BulkImportContainer>
	;
};

const mapStateToProps = (state: RootState) =>
{
	return {
		AuthToken: state.auth.userAuthToken,
		FarmKey: state.bulkImport.farmKey,
		FieldKey: state.bulkImport.fieldKey,
		SelectedGrowerId: state.ui.SelectedGrowerId,
		SelectedUserId: getCurrentActingUser(state).UserId,
		Geometry: state.bulkImport.geometry,
		Header: state.bulkImport.header,
		LoadingGeometry: state.bulkImport.isLoadingGeometry,
		LoadingProfile: state.bulkImport.isLoadingProfile,
		farms: state.bulkImport.farms
	};
};

const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		getGeometry: makeDispatch(dispatch, getBulkImportGeometry),
		getHeader: makeDispatch(dispatch, getBulkImportHeader),
		getProfile: makeDispatch(dispatch, getBulkImportProfile),
		setFarmKey: makeDispatch(dispatch, setFarmKey),
		setFieldKey: makeDispatch(dispatch, setFieldKey),
		clearBulkImport: makeDispatch(dispatch, clearState),
		saveBulkImport: makeDispatch(dispatch, saveBulkImportSession) 
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const BulkImport = connector(BulkImportComponent);
