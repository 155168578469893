import { assign, trim, trimEnd, trimStart } from 'lodash';
import { AppDispatch } from '../store/Store';
import { useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { globalSession } from '../../tracing/session';

/* Ad-hoc collection of Utility functions */

export const CustomSeriesName = 'Custom';

export const pathJoin = (...parts: string[]): string =>
{
	if (!parts)
	{
		return '';
	}

	if (parts.length === 1)
	{
		return parts[0];
	}

	const trimmed = parts.map((p, i) =>
	{
		if (i === 0)
		{
			return trimEnd(p, '/');
		}
		else if (i === parts.length - 1)
		{
			return trimStart(p, '/');
		}
		else
		{
			return trim(p, '/');
		}
	});
	return trimmed.join('/');
};

// Copies members and functions of targetted objects
// Ensures new object is returned
export const cloneAssign = <T>(obj: T, ...merge: any[]) => 
{
	// This will also copy instance function
	return assign({}, obj, ...merge);
};

export const sleepOnIt = async (milliseconds: number) => 
{
	return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const waitForData = async (data: any[], retryLimit: number = 10): Promise<boolean> => 
{
	await sleepOnIt(200);
	let retryCounter: number = 0;
	while (retryCounter <= retryLimit && (data.includes(null)))
	{
		globalSession.error(`no data found, waiting for data to load. (${retryLimit - retryCounter}) retries remaining.`);
		await sleepOnIt(200);
		retryCounter++;
		if (retryCounter >= retryLimit)
		{
			return false;
		}
	}
	return true;
};

export const roundOff = (num: number, places: number) =>
{
	const x = Math.pow(10, places);
	return Math.round(num * x) / x;
};

export const roundUp = (num: number, places: number) =>
{
	const x = Math.pow(10, places);
	return Math.ceil(num * x) / x;
};


export function makeDispatch<T extends (...args: any) => any>(dispatch: AppDispatch, method: T) 
{
	return (...args: Parameters<T>) => dispatch(method(...args));
}

export type SortDirection = 'ascending' | 'descending';
// Utility sort
export const dynamicSort = (property: string, direction: SortDirection = 'ascending', property2?: string, direction2?:SortDirection) =>
{
	return function <T>(a: T, b: T): number
	{
		let first = direction === 'ascending' ? a : b;
		let second = direction === 'ascending' ? b : a;
		if (typeof (first[property]) === 'string')
		{
			const result = first[property].localeCompare(second[property], undefined, { numeric: true, sensitivity: 'base' });
			if(result !== 0)
			{
				return result;
			}
		}
		else
		{
			const result = (first[property] ?? 0) - (second[property] ?? 0);
			if(result !== 0)
			{
				return result;
			}
		}

		// If there is a second property, sort by that, otherwise they are equal.
		if(!property2)
		{
			return 0;
		}

		// compare to descending so 'undefined' defaults to effectively ascending
		first = direction2 === 'descending' ? b : a;
		second = direction2 === 'descending' ? a : b;

		if(typeof(first[property2]) === 'string')
		{
			return first[property2].localeCompare(second[property2], undefined, { numeric: true, sensitivity: 'base' });
		}
		
		return (first[property2] ?? 0) - (second[property2] ?? 0);
	};
};

/**
 * Simplified version of the dynamic sort above that works directly on strings (rather than string properties)
 */
export const stableStringSort = (a: string | undefined, b: string | undefined) => !a ? -1
	: !b ? 1
		: a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base', usage: 'sort' });

/**
 * Given an error of some kind, return the string representation.
 */
export function stringForError(err: unknown)
{
	if(typeof(err) === 'string')
	{
		return err;
	}

	if(typeof((err as any)['message']) === 'string')
	{
		return (err as any)['message'];
	}

	return String(err);
}

export const useInterval = (callback: () => void, delay: number | null) =>
{
	const savedCallback = useRef(callback);

	// Remember the latest callback if it changes.
	useEffect(() =>
	{
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() =>
	{
		// Don't schedule if no delay is specified.
		if (delay === null) return;
	
		const id = setInterval(() => savedCallback.current(), delay);

		return () => clearInterval(id);
	}, [delay]);
};

/**
 * Helper to return a partial hex string for a number value
 * @param num
 * @returns string
 */
const convertToHex = (num: number) =>
{
	const hex = num.toString(16);
	return hex.length === 1 ? `0${hex}` : hex;
};

/**
 * Convert a Red, Green and Blue number to a hex string
 * @param r Red
 * @param g Green
 * @param b Blue
 */
export const rgbToHex = (r: number, g: number, b: number) =>
{
	return `#${convertToHex(r)}${convertToHex(g)}${convertToHex(b)}`;
};

/**
 * Comma deliminted string of R,G,B to a hex string
 * @param color Comma delimited string of Red, Green, Blue values
 */
export const colorToHex = (color: string) =>
{
	const parts = color.split(',');
	return rgbToHex(Number(parts[0]), Number(parts[1]), Number(parts[2]));
};

/**
 * Create a random vibrant color
 * @param numOfSteps: Total number steps to get color, means total colors
 * @param step: The step number, means the order of the color
 */
export const generateRandomColor = (numOfSteps: number, step: number) =>
{
	// This function generates vibrant, "evenly spaced" colors (i.e. no clustering).
	// This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
	// From: https://stackoverflow.com/questions/1484506/random-color-generator
	// HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
	let r: number = 0, g: number = 0, b: number = 0;
	const h = step / numOfSteps;
	const i = ~~(h * 6);
	const f = h * 6 - i;
	const q = 1 - f;
	switch(i % 6)
	{
		case 0: r = 1; g = f; b = 0; break;
		case 1: r = q; g = 1; b = 0; break;
		case 2: r = 0; g = 1; b = f; break;
		case 3: r = 0; g = q; b = 1; break;
		case 4: r = f; g = 0; b = 1; break;
		case 5: r = 1; g = 0; b = q; break;
	}
	const c = '#' + ('00' + (~ ~(r * 255)).toString(16)).slice(-2) + ('00' + (~ ~(g * 255)).toString(16)).slice(-2) + ('00' + (~ ~(b * 255)).toString(16)).slice(-2);
	return (c);
};

/**
 * Determine a light or dark text color to display across a given background color
 * @param bgColor 
 * @returns white or black color meant for text
 */
export const checkContrast = (bgColor: string) =>
{
	const theme = useTheme();

	// Function from: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
	const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
	const r = parseInt(color.substring(0, 2), 16); // hexToR
	const g = parseInt(color.substring(2, 4), 16); // hexToG
	const b = parseInt(color.substring(4, 6), 16); // hexToB

	const uicolors = [r / 255, g / 255, b / 255];

	const rgbArray = uicolors.map((col) =>
	{
		if (col <= 0.03928)
		{
			return col / 12.92;
		}
		return Math.pow((col + 0.055) / 1.055, 2.4);
	});
	const luminance = (0.2126 * rgbArray[0]) + (0.7152 * rgbArray[1]) + (0.0722 * rgbArray[2]);
	return (luminance > 0.220) ? theme.colors.darkGrey : theme.colors.white;
};