import React, { useCallback, useMemo } from 'react';
import { Dropdown, Form, Input, MenuProps, Select, Tooltip } from 'antd';
import { Button } from '../../../components/Button/Button';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { IRoleProjection } from '../../../logic/Models/Responses/UserAdmin/IRoleProjection';
import { IOrganizationProjection } from '../../../logic/Models/Responses/UserAdmin/IOrganizationProjection';
import { IUserProjection } from '../../../logic/Models/Responses/UserAdmin/IUserProjection';
import { IAccessControlProjection } from '../../../logic/Models/Responses/UserAdmin/IAccessControlProjection';
import { IInvitationTemplateProjection } from '../../../logic/Models/Responses/UserAdmin/IInvitationTemplateProjection';
import { IUpdateUserRequest } from '../../../logic/Models/Requests/UserAdmin/IUpdateUserRequest';
import { selectIsLoading } from '../../../logic/store/UserAdmin/UserAdminSelectors';
import { sendInvitationToUser } from '../../../logic/store/UserAdmin/UserAdminThunks';
const { Option } = Select;
const { TextArea } = Input;

/**
 * Present a UI to allow the editing of a user, activate/deactivate them, or send them an individual invitation
 */
export const EditUserForm = (props: { 
	roles: IRoleProjection[], 
	organizations: IOrganizationProjection[], 
	invitationTemplates: IInvitationTemplateProjection[],
	userEdit: IUserProjection, 
	accessControls: IAccessControlProjection[],
	toggleActivation: (u: IUserProjection) => unknown,
	saveUser:({id:string, update: IUpdateUserRequest}) => unknown
} ) => 
{
	const { roles, organizations, userEdit, accessControls, invitationTemplates: invitationTemplates, toggleActivation, saveUser } = props;
	const theme = useTheme();
	const [form] = Form.useForm();
	const isLoading = useSelector(selectIsLoading);
	
	// Wrap the deactivation step in a confirmation click
	const activateUser = useCallback(() => 
	{
		if(userEdit.IsDeactivated)
		{
			// reactivate
			toggleActivation(userEdit);
		}
		else 
		{
			toggleActivation(userEdit);
		}
	}, [userEdit.IsDeactivated, toggleActivation]);

	const saveEdits = useCallback((d: IUpdateUserRequest) => 
	{
		saveUser({id:userEdit.Id, update: d});
	}, [saveUser, userEdit.Id]);

	const dispatch = useDispatch();
	const handleMenuClick: MenuProps['onClick'] = useCallback(e => 
	{
		const invitationTemplateId = e.key;
		const invitationTemplate = invitationTemplates.find(c => c.Id === invitationTemplateId);
		if(!invitationTemplate)
		{
			return;
		}
		
		dispatch(sendInvitationToUser({
			user: userEdit,
			invitationTemplate: invitationTemplate
		}));
	}, [invitationTemplates, dispatch, userEdit]);

	const menuProps = useMemo(() => ({
		items: invitationTemplates.map(c => ({
			label: c.Name,
			key: c.Id
		})),
		onClick: handleMenuClick
	}), [invitationTemplates, handleMenuClick]);

	return <div style={{position: 'relative', display:'flex', flexDirection:'row', alignItems:'stretch',  margin: -14}}>
		<Form
			labelCol={{ span: 5 }}
			form={form}
			onFinish={saveEdits}
			style={{ display: 'flex', flexDirection: 'column', flexGrow: 0.66, flexBasis:0, margin: 16 }}
			initialValues={{
				'Name': userEdit.Name,
				'Company': userEdit.Company,
				'FoundationId': userEdit.FoundationId,
				'RoleId': userEdit.RoleId,
				'OrganizationId': userEdit.OrganizationId,
				'Notes': userEdit.Notes
			}}
		>
			<Form.Item 
				name='Name'
				label='Name'
			>
				<Input />
			</Form.Item>
			<Form.Item 
				name='Company'
				label='Company'
			>
				<Input />
			</Form.Item>
			<Form.Item 
				name='FoundationId'
				label={<div>Foundation ID<div style={{fontSize: 10, lineHeight: '8pt'}}>(SAP ID)</div></div>}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name='RoleId'
				label='Role'
			>
				<Select placeholder={'Pick the user\'s role'}>
					{
						roles.map(r => <Option key={r.Id} value={r.Id}>{ r.Name }</Option>)
					}
				</Select>
			</Form.Item>
			<Form.Item
				name='OrganizationId'
				label='Organization'
			>
				<Select placeholder={'Pick the user\'s organization'}>
					{
						organizations.map(o => <Option key={o.Id} value={o.Id}>{ o.Name }</Option>)
					}
				</Select>
			</Form.Item>
			<Form.Item
				name='Notes'
				label='Notes'
			>
				<TextArea rows={8} />
			</Form.Item>
		</Form>
		{/* Show login activity; this isn't updated by the system right now (9/16/2024) but I'm hopeful it will again shortly. */}
		<div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexBasis:0, margin: 16 }}>
			<div style={{ display: 'flex', flexDirection: 'column', padding:8, border:'1px solid black', borderRadius: 8}}>		
				<h3>Login Activity (GHX Fields Only)</h3>	
				<div>Last Successful Login:               {userEdit.LastLoginSuccess ? new Date(userEdit.LastLoginSuccess).toLocaleTimeString() : ''}</div>
				<div>Last Failed Login:                   {userEdit.LastLoginFailure}</div>
				<div>Number of failed attempts:           {userEdit.LastLoginFailureCount}</div>
			</div>
			{/* Show any access control entries that match this user. */}
			{
				accessControls.length > 0 && <div style={{ display: 'flex', flexDirection: 'column', padding:8, border:'1px solid black', borderRadius: 8, marginTop: 8}}>	
					<h3>Access Control Entries</h3>
					<table>
						<thead style={{backgroundColor: 'black', color: 'white'}}>
							<td>Org</td><td>Level</td><td>Parent</td><td>Title</td><td>FID</td><td>Name</td>
						</thead>
						<tbody>
							{
								accessControls.map(ac => <tr key={ac.UserExternalId + ac.NodeExternalId}>
									<td>{ac.OrganizationName}</td>
									<td>{ac.LevelName}</td>
									<td>{ac.ParentName}</td>
									<td>{ac.Title}</td>
									<td>{ac.FoundationId}</td>
									<td>{ac.Name}</td>
								</tr>)
							}
						</tbody>
					</table>
				</div>
			}
			<div style={{flexGrow: 1}} />
			{/* Actions to edit this user */}
			<div style={{ display: 'flex', flexDirection: 'column', padding:8, border:'1px solid black', borderRadius: 8, marginTop: 8}}>
				<Button style={{marginTop: 8}} variant='dark' onClick={activateUser}>
					{userEdit.IsDeactivated ? 'Activate' : 'Deactivate'}
				</Button>
				<Tooltip title={userEdit.LastLoginSuccess ? 'User has previously logged in' : 'Send an invitation to this user'}>
					<Dropdown menu={menuProps}>
						<Button 
							style={{
								color: userEdit.LastLoginSuccess ? 'grey' : 'black', 
								borderColor: userEdit.LastLoginSuccess ? 'grey' : 'black', 
								marginTop: 8}} 
							variant='outlined'>Send Invitation</Button>
					</Dropdown>
				</Tooltip>
				<Button style={{marginTop: 8}} variant='dark' type='submit' onClick={() => form.submit()}>Save</Button>
			</div>
		</div>
		<LoadingSpinner style={{position: 'absolute', top: 20, left: '50%', transform: 'translateX(-50%)'}} loading={isLoading}>
			Loading...
		</LoadingSpinner>
	</div>;
};